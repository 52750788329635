@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
  h3 {
    @apply text-lg;
  }
}

h1,
h2,
h3,
h4 {
  font-family: 'Racing Sans One', cursive;
  @apply mb-3;
}

p {
  @apply mb-3;
}

small {
  @apply uppercase
}

* {
  font-family: 'Open Sans', sans-serif;
}

:root {
  @apply bg-background text-white;
}


.bounce {
  animation: bounce 1.8s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}
